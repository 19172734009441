$(window).ready(function () {

	$(function () {
		var header = $(".top__nav");

		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll >= 300) {
				header.addClass("scrolled");
			} else {
				header.removeClass("scrolled");
			}
		});
	});

	$('.phone_us').mask('+7 (000) 000-00-00');
	$('.code_us').mask('00000');
	$('.registration .f-step #name').mask('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA', { translation: { 'A': { pattern: /[A-Z a-z А-Я а-я]/ } } });

	$("button.burger").click(function () {
		$(this).toggleClass("active");
		$('.menu__top').toggleClass("active");
		$('body').toggleClass("over_hide");
	});

	$(".user__account .tab").click(function () {
		$(".user__account .tab").removeClass("active").eq($(this).index()).addClass("active");
		$(".tab_item").hide().eq($(this).index()).fadeIn()
	}).eq(0).addClass("active");

	$(".registration .f-step .big__btn.nt").click(function () {
		$(".registration .f-step").hide();
		$(".registration .l-step").addClass('active').fadeIn();
	});
	$(".registration .f-step .big__btn.np").click(function () {
		$(".registration .f-step input").addClass('novalid');
	});
	$(".registration .l-step .big__btn.np").click(function () {
		$(".registration .l-step input").addClass('novalid');
	});
	$(".back__btn").click(function () {
		$(".registration .f-step").addClass('active').fadeIn();
		$(".registration .l-step").removeClass('active').hide();
	});

	animationClick('.registration .l-step .big__btn.np', 'shake');
	animationClick('.registration .f-step .big__btn.np', 'shake');

	function animationClick(element, animation) {
		element = $(element);
		element.click(
			function () {
				element.addClass('animated ' + animation);
				//wait for animation to finish before removing classes
				window.setTimeout(function () {
					element.removeClass('animated ' + animation);
				}, 2000);
			}
		);
	};
	$(".phone__confirm").click(function () {
		$(".confirm__tel").slideDown("fast");
	});

	$(".user__order__item__link").click(function () {
		$(this).parent().find(".order_info").slideToggle("fast");
		$("user__order__item").find(".order_info").slideUp("fast");
		$(this).parent().toggleClass("active");
	});

	$(function () {

		// Валидация email при регистрации
		var emailValid = /^([\w\.\-\+]{1,})([^\W])(@)([\w]{1,})(\.[\w]{1,})+$/;
		var nameValid = /^[A-Za-zА-Яа-я]{2}/;
		$('.registration .f-step input').on('keyup', function () {
			emailValid.test($('#mail').val()) & nameValid.test($('#name').val()) ? $(this).removeClass('novalid').addClass('valid') & $('.registration .f-step .big__btn.nt').removeClass('hide').addClass('show') & $('.registration .f-step .big__btn.np').removeClass('show').addClass('hide') : $(this).addClass('novalid').removeClass('valid') & $('.registration .f-step .big__btn.nt').addClass('hide').removeClass('show') & $('.registration .f-step .big__btn.np').addClass('show').removeClass('hide');
		});

		$('#mail').on('keyup', function () {
			$('.message').hide();
			emailValid.test($(this).val()) ? $('.message.success').show() & $(this).removeClass('novalid').addClass('valid') : $('.message.error').show() & $(this).addClass('novalid').removeClass('valid');
		});

		$('#name').on('keyup', function () {
			$('.message2').hide();
			nameValid.test($(this).val()) ? $('.message2.success').show() & $(this).removeClass('novalid').addClass('valid') : $('.message2.error').show() & $(this).addClass('novalid').removeClass('valid');
		});

		// Валидация телефона при регистрации
		var phoneValid = /^\+?7? ?\(?[(][0-9]{3}[)] [0-9]{3}-[0-9]{2}-[0-9]{2}/;
		$('#phone_us').on('keyup', function () {
			phoneValid.test($(this).val()) ? $(this).removeClass('novalid').addClass('valid') & $('.registration .l-step .big__btn.nt').removeClass('hide').addClass('show') & $('.registration .l-step .big__btn.np').removeClass('show').addClass('hide') : $(this).addClass('novalid').removeClass('valid') & $('.registration .l-step .big__btn.nt').addClass('hide').removeClass('show') & $('.registration .l-step .big__btn.np').addClass('show').removeClass('hide');
		});
	});

	$('#cr__site').on('click', function () {
		$.fancybox.open($('.inline-gallery-1'), {
			touch: false,
			infobar: false
		});
	});

	$("#cr__site").on('click', function () {
		$.fancybox.open({
			touch: false,
			src: '#create-site',
			type: 'inline'
		});

	});

});



